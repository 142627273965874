
$orange: #F96700;
$font-gray:#7E7E7E;

@mixin ellipsis-text {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
@mixin ellipsis-two {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

@mixin flex-center {
    display:flex;
    align-items:center;
    justify-content:center;
}

@mixin content-title {
    font-size:20rem;
    font-weight:550;
}