@import './variables.scss';

html{
	width:100%;
	height:100%;
}
body{
	width:100%;
	height:100%;
	overflow:auto;
	font:16rem/150% "microsoft yahei", Arial, Verdana, "\5b8b\4f53";
	color:#2d2d2d;
	background:#F6F8FA;
	_background-image:url(about:blank);
	_background-attachment:fixed;
	overflow:hidden;
}

::-webkit-scrollbar {
	width: 8px; 
}
::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 3px;
}
::selection {
    background: #F96700;
    color: #fff;
}
a, address, b, big, blockquote, body, center, cite, code, dd, del, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, label, legend, li, ol, p, pre, small, span, strong, u, ul, var {
	margin:0;
	padding:0;
    box-sizing:border-box;
}
ol, ul {
	list-style:none
}
:focus {
	outline:0
}
img {
	border:0;
	vertical-align:middle
}
table {
	empty-cells:show;
	border-collapse: collapse;
}
ins {
	text-decoration:none
}
del {
	text-decoration:line-through
}
em {
	font-style:normal
}
button::-moz-focus-inner, input[type=button]::-moz-focus-inner, input[type=file]>input[type=button]::-moz-focus-inner, input[type=reset]::-moz-focus-inner, input[type=submit]::-moz-focus-inner {
	border:0;
	padding:0
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button{
	-webkit-appearance: none !important;
	margin: 0;
}
input[type="number"]{-moz-appearance:textfield;}

.clearfix:after {
	content:".";
	display:block;
	height:0;
	clear:both;
	visibility:hidden
}
* html .clearfix {
	height:1%
}
.clearfix {
	display:block
}
*+html .clearfix {
	min-height:1%
}
h1 {
	font:24rem"microsoft yahei", "\5b8b\4f53"
}
h2, h3 {
	@include content-title;
}
h4, h5, h6 {
	font-size:16rem
}
em, i {
	font-style: normal;
}
a {
	color:#666;
	text-decoration:none
}
.e_orange{
    color:$orange;
}
.e_center{
    text-align:center;
}

.l_col{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	align-items:center;

	> *{
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	>.full{
		flex:1;
	}
	
}
.l_col-space>*{
	margin: 0 5rem;
}
.l_col-space-2>*{
	margin: 0 10rem;
}
.l_col-space-3>*{
	margin: 0 15rem;
}
.l_col-space-4>*{
	margin: 0 20rem;
}
.l_col-space>*:first-child, .l_col-space-2>*:first-child, .l_col-space-3>*:first-child, .l_col-space-4>*:first-child{
	margin-left: 0;
}

#root{
    height:100%;
    overflow-y:auto;
}
.container{
    padding-bottom:10rem;
    min-height: calc(100vh - 230rem);
}
.white-container{
    padding-bottom:10rem;
    background:#fff;
}
.banner{
    width:100%;

    img{
        width:100%;
        height:auto;
    }
}
.wrap{
    margin:0 auto;
    max-width:1600rem;
}
.wrap-1200{
    margin:0 auto;
    max-width:1200rem;
}
.c_title{
    position:relative;
    margin:60rem 0;
    padding-top:40rem;

    .text{
        position:relative;
        z-index:1;
        font-size:50rem;
        font-weight:600;
    }
    .sub-title{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        z-index:0;
        font-size:50rem;
        font-weight:600;
        color:#F3F3F3;
    }
}
.breadcrumb{
    margin:40rem 0;
    color:$font-gray;
    font-size:12rem;
}
.breadcrumb-sub{
    margin:20rem 0;
    color:$font-gray;
    font-size:12rem;
}