@import './variables.scss';

.news_list{
    margin:30rem 0;
    padding-bottom:30rem;
    align-items:flex-start;
    border-bottom:1px solid #E5E5E5;
}
.news_list_image img{
    display:block;
    margin-right:50rem;
    width:560rem;
    height:340rem;
}
.news_list_main{
    font-size:14rem;
    color:$font-gray;
    line-height:2;
}
.news_list_title, .title_text{
    margin-bottom:15rem;
    font-size:24rem;
    font-weight:600;
    color:#2d2d2d;
}
.news_list_title:before{
    display:block;
    margin-right:14rem;
    width: 6rem;
    height: 22rem;
    content:'';
    background:$orange;
}
.news_list_more{
    margin-top:40rem;
}
.news_list_more a{
    color:$font-gray;
}
.news_list_more a:hover{
    color:$orange;
}
.title{
    margin-bottom:40rem;;
    padding:40rem 0 30rem;
    text-align:center;
    border-bottom:1px solid #E5E5E5;
}
.title_info{
    padding-top:5rem;
    color:#9FA19F;
    font-size:16rem;
}
.content{
    margin-top:10rem;
    margin-bottom:30rem;
    overflow:hidden;
}
.content > p{
    margin:20rem 0;
    font-size:14rem;
    color:#3D3D3D;
}
.image{
    margin:20rem 0;
}
.image img{
    display:block;
    max-width:100%;
}
.image_info{
    margin-top:20rem;
    font-size:12rem;
    color:$font-gray;
    text-align:center;
}
.col_image1{
    width:224rem;
    height:200rem;
}
.col_image2{
    width:585rem;
    height:300rem;
}