@import './variables.scss';

.footer{
    background: #F6F8FA;
    padding:40rem 20rem 20rem;
    line-height:30rem;
}
.footer_col{
    margin:0 auto;
    max-width:1200rem;
    align-items:flex-start;
}
.side{
    font-size:22rem;
    font-weight:600;
    color:#27282D;
}
.nav{
    margin-bottom:20rem;
}
.nav_item{
    margin-right:50rem;
    color:#2d2d2d;
    font-size:500;
}
.active{
    color:$orange;
}
.desc, .desc a{
    font-size:14rem;
    color:#666;
}
.desc_left{
    width:200rem;
}