@import './variables.scss';

$headerHeight:70rem;

.header{
    width: 100%;
    height: $headerHeight;
    background:#fff;
}
.header_col{
    margin:0 auto;
    width: 1200rem;
    height:100%;
}
.logo{
    width:120rrem;
    height:46rem;
}
.float_nav{
    position:relative;
    overflow:visible;
}
.float_nav:after{
    position:absolute;
    content:'';
    bottom:-10rem;
    left:0;
    right:-120rem;
    height:10rem;
}
.product_box{
    position:absolute;
    left:0;
    top:80rem;
    width:200rem;
    background:#fff;
    border-radius:10rem;
    overflow:hidden;
}
.product_item{
    display:block;
    padding-left:30rem;
    width:100%;
    height:40rem;
    line-height:40rem;
    font-size:12rem;
}
.product_item:hover{
    color:$orange;
}
.nav_item{
    display:block;
    position:relative;
    height:$headerHeight;
    padding:0 10rem;
    @include flex-center;
}
.active:after{
    content:'';
    position:absolute;
    bottom:0;
    left:50%;
    margin-left:-30rem;
    width:60rem;
    height:4rem;
    background:$orange;
    font-size:550;
    color:#2D2D2D;
}
.nav_item:hover{
    color:$orange;
}
.side{
    margin-left:80rem;
}
.qrcodeImage{
    margin-left:10rem;
    padding:10rem 0;
    position:relative;
    visibility:visible;
}
.qrcodeImage > img{
    width:32rem;
    height:32rem;
}
.qrcodeImage > div{
    display:none;
    position:absolute;
    top:50rem;
    right:-170rem;
    padding:25rem;
    border-radius:6rem;
    background:#fff;
    box-shadow: 5rem 3rem 10rem rgba(0,0,0,0.3);
}
.qrcodeImage > div img{
    width:320rem;
    height:320rem;
}
.qrcodeImage:hover > div{
    display:block;
}