.title{
    position:relative;
    margin:60rem;
    text-align:center;
}
.title:after{
    content:'';
    position:absolute;
    z-index:0;
    width:360rem;
    height:1px;
    left:50%;
    top:50%;
    margin-left:-180rem;
    background:#707070;
}
.title_text{
    width: 150rem;
    margin:0 auto;
    position:relative;
    z-index:1;
    background:#fff;
    font-size:20rem;
    font-weight:500;
}
.content{
    min-height:200rem;
    font-size:14rem;
    text-align:center;
}
.content_image{
    display:block;
    width:100%;
    height:auto;
}