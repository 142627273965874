@import './variables.scss';

.list1{
    margin:30rem 0 80rem;
    flex-wrap:wrap;
    justify-content:flex-start;
}
.list1_item{
    margin:30rem 0;
    width:calc(100% / 3);
    height:78rem;
    align-items:flex-start;
}
.list1_icon img{
    margin-right:15rem;
    width:78rem;
    height:78rem;
}
.list1_title{
    margin:5rem 0;
    height:24rem;
    font-weight:600;
}
.list1_desc{
    color:$font-gray;
    line-height:1.3;
}
.list2{
    margin:80rem 0;
}
.list2_item{
    position:relative;
    width:388rem;
    height:240rem;
}
.list2_image img{
    position:relative;
    z-index:0;
    width:388rem;
    height:240rem;
}
.list2_item_big{
    width:586rem;
    height:360rem;
}
.list2_image_big img{
    width:586rem;
    height:360rem;
}
.list2_title{
    position:absolute;
    z-index:1;
    left:30rem;
    bottom:24rem;
    @include content-title;
    color:#fff;
}
.list3{
    margin:80rem 0 30rem;
    flex-wrap: wrap;
    justify-content:flex-center;
}
.list3_item{
    margin-bottom:50rem;
    width:50%;
    height:200rem;
    overflow:hidden;
    border:1px solid #DEDEDE;
}
.list3_item:nth-child(2n-1){
    border-right:0;
}
.list3_image img{
    margin-right:20rem;
    width:200rem;
    height:200rem;
}
.list3_title{
    @include content-title;
}
.list3_content{
    margin-top:8rem;
    height:80rem;
    color:$font-gray;
}
.list4_item{
    margin-top:80rem;
    padding-bottom:60rem;
}
.list4_item:nth-child(2n){
    flex-direction:row-reverse;
}
.list4_title{
    position:relative;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    font-size:40rem;
    font-weight:600;
}
.list4_text{
    position:relative;
    z-index:1;
}
.list4_title:before{
    display:block;
    margin-right:20rem;
    content:'';
    width: 6rem;
    height: 20rem;
    background: #6C7BFF;
    border-radius: 2rem;
}
.list4_no{
    position:absolute;
    top:-30rem;
    left:0;
    width:100%;
    z-index:0;
    font-size:100rem;
    font-weight:600;
    color:#F3F3F3;
}
.list4_content{
    margin-top:40rem;
}
.list4_sub_title{
    margin-bottom:8rem;
    @include content-title;
}
.list4_desc{
    font-size:18rem;
    color:$font-gray;
}