@import './variables.scss';

@mixin nav2-common {
    position:relative;
	padding:80rem;
}
@mixin nav2-text {
    position:relative;
    z-index:1;
}

.nav1{
    margin-top: -50rem;
}
.nav1 > div{
    padding:25rem 20rem 25rem 45rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 1px rgba(177,177,177,0.16);
}
.nav1 > div img{
    margin-right:10rem;
    width:44rem;
    height:44rem;
}
.nav1_title{
    margin-bottom:10rem;
    font-size:20rem;
}
.nav1_desc{
    font-size:14rem;
    color:$font-gray;
}
.nav2{
    margin:80rem 0;
}
.nav2_item1{
    width:778rem;
    height:965rem;
    @include nav2-common;
}
.nav2_item2, .nav2_item3{
    margin-top:53rem;
    width:778rem;
    height:456rem;
    @include nav2-common;
}
.nav2_item2:first-child{
    margin-top:0;
}
.nav2_item2{
    color:#fff;
}
.nav2_title{
    font-size:40rem;
    font-weight:600;
    @include nav2-text;
}
.nav2_desc{
    margin:30rem 0 40rem;
    max-width:320rem;
    font-size:18rem;
    line-height:1.6;
    @include nav2-text;
}
.nav2_item3 .nav2_desc{
    max-width:400rem;
}
.nav2_image{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 0;
}
.nav2_more1, .nav2_more2{
    display:block;
    margin-top:40rem;
    width:40rem;
    height:28rem;
    overflow:hidden;
    text-indent:-200rem;
    @include nav2-text;
}
.nav2_more1{
    background:url(../imgs/index-more1.png) no-repeat center center;
    background-size:100% 100%;
}
.nav2_more2{
    background:url(../imgs/index-more2.png) no-repeat center center;
    background-size:100% 100%;
}
.nav3_box{
    padding:40rem 0;
    background:url(../imgs/nav3-bg.png) no-repeat center top;
    background-size:1317rem 782rem;
}
.nav3{
    position:relative;
    padding-bottom:60rem;
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    flex-wrap:wrap;
}
.nav3:after{
    position:absolute;
    content:'';
    bottom:66rem;
    left:50%;
    margin-left:-545rem;
    width:1090rem;
    height:1px;
    background:#E8E8E8;
    z-index:0;
}
.nav3_item{
    position:relative;
    margin-top:40rem;
    width:512rem;
    height:500rem;
    text-align:center;
}
.nav3_item:after{
    content:'';
    position:absolute;
    bottom:3rem;
    left:50%;
    margin-left:-3rem;
    width:6rem;
    height:6rem;
    border-radius:50%;
    background:#393939;
    z-index:1;
}
.nav3_no{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    text-align:center;
    font-size:120rem;
    font-weight:600;
    color:#F8F8F8;
}
.nav3_item img{
    display:block;
    margin-bottom:30rem;
    width:512rem;
    height:330rem;
}
.nav3_title{
    margin-bottom:10rem;
    @include content-title;
}
.nav3_desc{
    font-size:12rem;
    color:$font-gray;
}